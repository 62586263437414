<template>
    <div class="container mercur-attribute-selector">
        <div class="row">
            <div :class="layout === 'vertical' ? 'col-12' : 'col-6'">
                <v-select
                    class="select-dropdown"
                    v-model="selectedAttribute"
                    placeholder="Select an attribute"
                    :options="attributes"
                    :taggable="true"
                    data-test="mercurAttributeNamesList"
                >
                    <template slot="option" slot-scope="item">
                        <span data-test="mercurAttributeNamesOption">{{item.label}} <em v-if="isNewAttribute(item.label)">(new)</em></span>
                    </template>
                </v-select>
            </div>
            <div :class="layout === 'vertical' ? 'col-12' : 'col-6'">
            <v-select
                class="select-dropdown"
                v-model="attribute"
                :disabled="selectedAttribute === null || loadingAttributeOptions"
                :placeholder="loadingAttributeOptions ? 'loading...' : 'Select an attribute option'"
                :options="filteredAttributeOptions"
                :taggable="true"
                data-test="mercurAttributeOptionsList"
            >
                <template slot="option" slot-scope="item">
                    <span data-test="mercurAttributeOptionOption">{{item.label}} <em v-if="isNewAttributeOption(item.label)">(new)</em></span>
                </template>
            </v-select>
            </div>
        </div>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import vSelect from 'vue-select'

export default {
    name: 'MercurAttributeSelector',
    components: { vSelect },
    props: {
        value: {},
        attributes: {},
        initialAttributeName: {},
        initialAttributeOption: {},
        legacyAttributeId: {},
        layout: {},
        debounceTime: {
            default: 400,
            required: false,
            type: Number,
        },
    },
    data () {
        return {
            selectedAttribute: null,
            attributeOptions: null,
            attribute: null,
            debouncer: null,
            unwatchAttributeOptions: null,
        }
    },
    computed: {
        loadingAttributeOptions () {
            return this.attributeOptions === null && this.selectedAttribute !== null
        },
        filteredAttributeOptions () {
            if (!this.attributeOptions) {
                return []
            }
            return this.attributeOptions.flatMap(attributeOption => attributeOption.attributeOption)
        },
    },
    methods: {
        isNewAttribute (attributeName) {
            return !this.attributes.includes(attributeName)
        },
        isNewAttributeOption (attributeOption) {
            return !this.attributeOptions.find(attribute => attribute.attributeOption === attributeOption)
        },
    },
    watch: {
        selectedAttribute (attributeName) {
            this.attributeOptions = null
            this.attribute = null

            if (!this.isAllowedTo('getAttributeOptionsByAttributeName')) {
                return
            }

            if (!attributeName) {
                return
            }

            if (this.isNewAttribute(attributeName)) {
                this.attributeOptions = []
                return
            }

            clearTimeout(this.debouncer)

            this.debouncer = setTimeout(() => {
                const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST_OPTIONS.replace('{attributeName}', attributeName)
                this.addJob(url)
                this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    this.attributeOptions = data.data.items
                }).catch(data => {
                    this.$root.$emit('notification:global', {
                        message: `Getting attribute options failed. Please try again.`,
                        type: 'error',
                        errors: data,
                    })
                }).finally(() => {
                    this.finishJob(url)
                })
            }, this.debounceTime)
        },
        attribute: {
            async handler (value) {
                if (!value || this.attributeOptions === null) {
                    this.$emit('input', null)
                    return
                }

                if (this.isNewAttributeOption(this.attribute)) {
                    this.$emit('input', {
                        ...this.value,
                        attributeId: null,
                        attributeOption: this.attribute,
                        attributeName: this.selectedAttribute,
                        isNew: true,
                    })
                    return
                }

                const attributeId = this.attributeOptions.find(attributeOption => attributeOption.attributeOption === value).attributeId

                this.$emit('input', {
                    ...this.value,
                    attributeId: attributeId,
                    attributeOption: value,
                    attributeName: this.selectedAttribute,
                    legacyAttributeId: this.legacyAttributeId,
                })
            },
        },
    },
    created () {
        if (this.initialAttributeName) {
            this.selectedAttribute = this.initialAttributeName

            if (!this.initialAttributeOption) {
                return
            }

            this.unwatchAttributeOptions = this.$watch('attributeOptions', () => {
                if (this.attributeOptions === null) {
                    return
                }

                this.attribute = this.initialAttributeOption
                this.$nextTick(() => {
                    this.unwatchAttributeOptions()
                })
            })
        }
    },
}
</script>

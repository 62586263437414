<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Product configurations to approve
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="gridOptions"
            v-if="url"
            ref="grid"
            :url="url"
            :quickSearch="filters.search"
        ></data-table>
        <mercur-dialog :is-open.sync="dialogActive" width="80%" height="80%">
            <div slot="header" v-if="productConfiguration">
                <h2 class="font-weight-normal">{{productConfiguration.productConfigurationName}}</h2>
            </div>

            <div slot="default" v-if="productConfiguration">
                <div class="row">
                    <div class="col-4">
                        <h3>Attributes</h3>
                        <div
                            class="full-width striped mb-20"
                            v-for="(options, key) in productConfiguration.attributeConfiguration.selectedAttributes"
                            :key="key"
                        >
                            <div><strong @click="toggleExpand(key)">{{key}} {{ expanded[key] ? '▾' : '▴' }}</strong></div>
                            <div v-if="expanded[key]">
                                <div
                                    v-for="(attributeOption, keyOption) in options"
                                    :key="keyOption"
                                    :class="{'warning': productConfiguration.pendingAttributes[key] && productConfiguration.pendingAttributes[key].find(({ option }) => attributeOption.option === option) }"
                                >&nbsp;&nbsp;{{ attributeOption.option }}{{ getPostFixForAttributeOption(key, attributeOption) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <h3>Pending attributes</h3>
                        <table
                            class="full-width striped last-column-align-right mb-20"
                            v-for="(options, key) in productConfiguration.pendingAttributes"
                            :key="key"
                        >
                            <tr>
                                <td><strong>{{key}}</strong></td>
                                <td>
                                    <mercur-button
                                        v-if="options.filter(option => !option.approved).length > 1"
                                        class="btn btn-raised btn-yellow text-uppercase">Approve all
                                    </mercur-button>
                                </td>
                            </tr>
                            <template v-for="(attributeOption, keyOption) in options">
                                <tr :key="keyOption">
                                    <td width="65%">&nbsp;&nbsp;{{ attributeOption.option }}</td>
                                    <td>
                                        <span v-if="attributeOption.approved" class="green pr-20">
                                            Approved
                                        </span>
                                        <span v-else-if="attributeOption.replaced" class="green pr-20">
                                            Replaced
                                        </span>
                                        <template v-else>
                                            <mercur-button
                                                class="btn btn-raised btn-yellow text-uppercase"
                                                :disabled="submittingAttributes[attributeOption.attributeId]"
                                                @click="approveAttributeOption(key, attributeOption)">
                                                Approve
                                            </mercur-button>
                                            <mercur-button
                                                class="btn btn-raised text-uppercase"
                                                :disabled="submittingAttributes[attributeOption.attributeId] || replacingAttributesOpen[attributeOption.attributeId]"
                                                @click="openReplaceAttribute(key, attributeOption)">
                                                Replace
                                            </mercur-button>
                                        </template>
                                    </td>
                                </tr>
                                <tr v-if="replacingAttributesOpen[attributeOption.attributeId]" :key="`replacing_${keyOption}`">
                                    <td>
                                        &nbsp;&nbsp;replace with:<br />
                                        <mercur-attribute-selector
                                            :attributes="availableAttributes"
                                            @input="setReplaceAttribute(attributeOption.attributeId, $event, key)"

                                        ></mercur-attribute-selector>
                                    </td>
                                    <td>
                                        <mercur-button
                                            class="btn btn-raised btn-yellow text-uppercase"
                                            :disabled="!replacingAttributes[attributeOption.attributeId]"
                                            @click="submitReplaceAttribute(key, attributeOption, replacingAttributes[attributeOption.attributeId])"
                                        >Save</mercur-button>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                </div>
            </div>

            <div class="text-right" v-if="!isLoading">
                <mercur-button class="btn btn-raised text-uppercase" @click="dialogActive = false">Close</mercur-button>
                <mercur-button class="btn btn-raised text-uppercase" :disabled="!canSaveProduct" @click="saveProductConfiguration">Save product</mercur-button>
            </div>
            <mercur-progress-bar v-if="isSubmitting || isLoading" indeterminate></mercur-progress-bar>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import DataTable from '../../components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '../../components/GridHeader'
import { getDeepProperty } from '../../components/utils/Utils'
import MercurAttributeSelector from '../../components/attributes/MercurAttributeSelector'
export default {
    name: 'ProductsForApprovalOverview',
    components: { MercurAttributeSelector, GridHeader, DataTable },
    data () {
        return {
            expanded: {},
            dialogActive: false,
            allAttributes: {},
            submittingAttributes: {},
            replacingAttributesOpen: {},
            replacingAttributes: {},
            availableAttributes: null,
            isSaving: false,
            isLoading: false,
            filters: {
                search: null,
            },
            gridOptions: {
                columns: {
                    'Product': {
                        field: 'productConfigurationName',
                        onClick: (value, data) => {
                            this.openProductConfiguration(data)
                        },
                    },
                },
            },
            url: CONFIG.API.ROUTES.PRODUCTS.APPROVAL.OVERVIEW,
            productConfiguration: null,
        }
    },
    computed: {
        isSubmitting () {
            return this.isSaving || Object.values(this.submittingAttributes).some(value => value)
        },
        canSaveProduct () {
            if (!this.productConfiguration || this.isSubmitting) {
                return false
            }

            return Object.values(this.productConfiguration.pendingAttributes).flat().every(attribute => attribute.replaced || attribute.approved)
        },
    },
    methods: {
        toggleExpand (key) {
            this.$set(this.expanded, key, !this.expanded[key])
        },
        getPostFixForAttributeOption (attributeName, attributeOption) {
            const attributeOptions = this.allAttributes[attributeName]
            if (!attributeOptions) {
                return ''
            }

            const option = attributeOptions.find(({ option }) => attributeOption.option === option)

            if (option && option.postfix) {
                return option.postfix
            }
            return ''
        },
        openProductConfiguration (productConfiguration) {
            this.dialogActive = true
            this.$set(this, 'productConfiguration', null)
            this.isLoading = true
            this.$store.dispatch('products/getProductDetailsForSupplierAndProductConfigurationId', {
                supplierId: productConfiguration.supplierId,
                productConfigurationId: productConfiguration.productConfigurationId,
            }).then(productConfigurationData => {
                this.$set(this, 'productConfiguration', productConfigurationData)
                this.$set(this.expanded, Object.keys(this.productConfiguration.attributeConfiguration.selectedAttributes)[0], true)
            }).finally(() => {
                this.isLoading = false
            })
        },
        async approveAttributeOption (attributeKey, attributeOption) {
            this.addJob(attributeOption.attributeId)
            this.$set(this.submittingAttributes, attributeOption.attributeId, true)
            this.$delete(this.replacingAttributesOpen, attributeOption.attributeId)
            const payload = {
                attributeName: attributeKey,
                attributeOption: attributeOption.option,
                attributeId: attributeOption.attributeId,
            }
            const result = await this.$api.post(CONFIG.API.ROUTES.PRODUCTS.APPROVAL.APPROVE_ATTRIBUTE, payload).catch(err => {
                return err
            })

            if (result.status === 200 || getDeepProperty('data.exceptions.0.error', result, '').includes('Attribute already approved')) {
                this.$delete(this.submittingAttributes, attributeOption.attributeId)
                this.finishJob(attributeOption.attributeId)
                this.$set(this.productConfiguration.pendingAttributes[attributeKey].find(({ attributeId }) => attributeId === attributeOption.attributeId), 'approved', true)
            } else {
                this.$root.$emit('notification:global', {
                    message: `Approving attribute failed. Please try again.`,
                    type: 'error',
                })
            }
        },
        openReplaceAttribute (attributeKey, attributeOption) {
            this.$set(this.replacingAttributesOpen, attributeOption.attributeId, true)
        },
        setReplaceAttribute (attributeId, $event, oldAttributeKey) {
            if ($event.isNew) {
                const attributeOption = {
                    option: $event.attributeOption,
                    attributeId,
                }
                this.approveAttributeOption($event.attributeName, attributeOption)
                this.$set(this.productConfiguration.pendingAttributes[oldAttributeKey].find(option => option.attributeId === attributeId), 'replaced', true)
                const selectedAttributes = this.productConfiguration.attributeConfiguration.selectedAttributes

                // find the current selected pending attributeOption and delete it
                const currentAttributeOptionIndex = selectedAttributes[oldAttributeKey].findIndex(({ option }) => option === attributeOption.option)
                this.$delete(selectedAttributes[oldAttributeKey], currentAttributeOptionIndex)

                const newAttributeKey = $event.attributeName
                const newAttributeOption = $event.attributeOption

                if (!Array.isArray(selectedAttributes[newAttributeKey])) {
                    this.$set(selectedAttributes, newAttributeKey, [])
                }

                selectedAttributes[newAttributeKey].push({
                    option: newAttributeOption,
                    alternative: newAttributeOption,
                })

                this.$delete(this.replacingAttributesOpen, attributeOption.attributeId)
                return
            }
            this.$set(this.replacingAttributes, attributeId, $event)
        },
        submitReplaceAttribute (attributeKey, attributeOption, $event) {
            const selectedAttributes = this.productConfiguration.attributeConfiguration.selectedAttributes

            // find the current selected pending attributeOption and delete it
            const currentAttributeOptionIndex = selectedAttributes[attributeKey].findIndex(({ option }) => option === attributeOption.option)
            this.$delete(selectedAttributes[attributeKey], currentAttributeOptionIndex)

            const newAttributeKey = $event.attributeName
            const newAttributeOption = $event.attributeOption

            if (!Array.isArray(selectedAttributes[newAttributeKey])) {
                this.$set(selectedAttributes, newAttributeKey, [])
            }

            selectedAttributes[newAttributeKey].push({
                option: newAttributeOption,
                alternative: newAttributeOption,
            })

            this.$set(this.productConfiguration.pendingAttributes[attributeKey].find(({ attributeId }) => attributeId === attributeOption.attributeId), 'replaced', true)
            this.$delete(this.replacingAttributesOpen, attributeOption.attributeId)
        },
        saveProductConfiguration () {
            const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.SAVE.replace('{supplierId}', this.productConfiguration.supplierId).replace('{productConfigurationId}', this.productConfiguration.productConfigurationId)
            const jobName = url
            this.isSaving = true
            this.addJob(jobName)
            const payload = {
                ...this.productConfiguration,
                productConfigurationStatus: 'UNPUBLISHED',
                productConfigurationName: this.productConfiguration.productConfigurationName,
                selectedAttributesWithOptions: this.productConfiguration.attributeConfiguration.selectedAttributes,
                blacklists: this.productConfiguration.attributeConfiguration.blacklistedAttributes,
                selectedFacilities: this.productConfiguration.facilities,
                selectedPackageConfiguration: this.productConfiguration.packageConfiguration,
                cutoffTimeConfigurations: this.productConfiguration.cutoffTimeConfigurations,
            }

            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Product saved successfully',
                })
                this.dialogActive = false
                this.$refs.grid.refreshGrid()
            }).catch(() => {
                this.$root.$emit('notification:global', {
                    message: 'Saving product failed',
                    type: 'error',
                })
            }).finally(() => {
                this.isSaving = false
                this.finishJob(jobName)
            })
        },
    },
    created () {
        const mercurAttributesUrl = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.GET
        this.addJob(mercurAttributesUrl)
        this.$api.get(mercurAttributesUrl).then(({ data }) => {
            this.availableAttributes = Object.keys(data.data)
            this.allAttributes = data.data
        }).finally(() => {
            this.finishJob(mercurAttributesUrl)
        })
    },
}
</script>
